export default {
  "carouselText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaduria #2 Monteria"])},
  "buttonContactCarousel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])},
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "consultas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiries"])},
    "resoluciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
    "transparencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparency"])},
    "normatividad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normativity"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])}
  },
  "location": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find us"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cl 24 2-43 L-1 Centro / Montería, Córdoba"])}
  },
  "faqs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently asked questions"])}
  },
  "footer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest links"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaduria Urbana Segunda de Montería"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cl 24 2-43 L-1 Centro / Montería, Córdoba"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(604)7835093"])},
    "cellphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3126102892"])}
  },
  "content": {
    "titleSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest access"])},
    "desSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section you will find quick access to relevant information"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique national format"])},
    "des1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can consult and download the format from here"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consult Publications"])},
    "des2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can consult publications ordered by year"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirements for procedures"])},
    "des3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consult here the necessary requirements for your procedures"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our curator"])},
    "des4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the decree 2150 from 1995, borns the public figure of Urban Curador, learn more here"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "des5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to know more about us and our work, access here"])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparency"])},
    "des6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consult here the relevant information about our urban curaduria segunda of Montería"])}
  },
  "us": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find information of the organization and the operation of our Curaduria Urbana Segunda de Montería"])},
    "mision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mision"])},
    "desMision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet the requirements of citizens by providing clear and concise guidance that facilitates compliance with urban regulations for the granting of an Urban License."])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision"])},
    "desVision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintain our image of credibility by improving and updating the processes for issuing licenses, efficiently meeting user requirements."])},
    "politica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politics"])},
    "desPolitica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Curaduría is committed to improving its processes while maintaining its image of credibility, in order to meet the requirements of users in an efficient and pertinent manner in the issuance of urban planning licenses."])},
    "funciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation and social dutties"])},
    "desFunciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The urban curator is an individual in charge of studying, processing and issuing licenses for subdivision, urban planning, construction or demolition, and for the subdivision or subdivision of properties, at the request of the interested party in advancing subdivision, urbanization, building, demolition or subdivision projects. or subdivision of properties, in the zones or areas of the municipality or district that the municipal or district administration has determined to be within its jurisdiction. Urban curatorship implies the exercise of a public function to verify compliance with current urban planning and building regulations, through the granting of urbanization and construction licenses."])},
    "procesos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processes and procedures"])},
    "organigrama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization chart"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])}
  },
  "documents": {
    "buttonSee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See"])},
    "buttonDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find the information required for each one of the procedures you need"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaduria urbana segunda de Montería"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arq. Lila María Esquivel Rubio"])},
    "buttonHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in contact with us"])},
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important"])},
    "badgeDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find information of contact, and you can also send us a message"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle 24 No. 2-43 local 1 / Monteria-Cordoba"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaduría Urbana Segunda de Montería"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(604)7835093"])},
    "cellphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+57 3126102892"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact form"])},
    "formDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete this information to contact with us"])},
    "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "lastNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
    "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailNotLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail for judicial notifications"])},
    "messageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "addressLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "telephoneLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "cellphoneLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellphone"])},
    "sendBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "googleDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site is protected by reCAPTCHA and the Google"])},
    "googlePrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "googleTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apply"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office hours"])},
    "scheduleFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office hours:"])},
    "scheduleHor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday to Friday from 7:00 a.m. to 5:00 p.m. to 4:00 p.m."])}
  },
  "transparency": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparency"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In compliance with Law 1712 of 2014, Decree 103 of 2015 and Resolution 3564 of 2015 of MINTIC, the Second Urban Curator of Montería makes the following information available to citizens:"])},
    "locationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localization and office hours"])},
    "locationDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle 24 No. 2-43 local 1 Lat: 8.7542 Lon: -75.8883"])},
    "scheduleTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office hours"])},
    "scheduleDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday to Friday from 7:00 a.m. to 4:00 p.m."])},
    "emailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailNotTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email for judicial notifications"])},
    "addressTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "addressDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle 24 No. 2-43 local 1"])},
    "telTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "telDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["57+ 7895392"])},
    "celTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellphone"])},
    "celDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+57 3126102892"])},
    "faxTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "faxDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["57+ 7895392"])},
    "emailInstTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutional email"])},
    "emailPhisTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical email or postal"])},
    "emailPhisDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle 24 No. 2-43 Local 1"])},
    "termsPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information treatment policy"])},
    "transactionalFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flowchart of procedures"])},
    "navLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])},
    "rates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rates 2023"])}
  },
  "resolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find the publications ordered in folders by created year"])},
    "btnGoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to folder"])},
    "res2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications created in 2019"])},
    "res2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications created in 2020"])},
    "res2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications created in 2021"])},
    "res2022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications created in 2022"])},
    "res2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications created in 2023"])},
    "res2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications created in 2024"])},
    "res2025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications created in 2025"])},
    "denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denied"])},
    "otherAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other actions"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
    "othersFallo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sixth Administrative Court of Monteria"])},
    "othersDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other publications"])}
  },
  "normativity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normativity"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning units"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We attach all the necessary information for citizenship"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
    "decreto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decree 1077 of 2015"])},
    "pot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POT Montería"])},
    "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules and Decrees"])},
    "oriental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Eastern edge"])},
    "buttonSee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See"])},
    "buttonDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "occidental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Western edge"])},
    "sur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. South city"])},
    "centro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. City center"])},
    "norte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. North city"])}
  },
  "uniqueForm": {
    "big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the national unique form"])},
    "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique national form"])},
    "btnDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
  },
  "notFound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 error"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found."])},
    "des1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry, we couldn't find the page you are looking for."])},
    "goHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])}
  }
}